import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { MatDialog } from "@angular/material/dialog";
import { FeeTypeEnum } from "src/app/order/enums/fee-type.enum";
import { Messages } from "src/app/order/messages/order.messages";
import { EconomicGroupService } from "src/app/order/services/external/economic-group/economic-group.service";
import { FeeService } from "src/app/order/services/external/fee/fee.service";
import { DialogService } from "src/app/order/services/internal/dialog/dialog.service";
import { LoadingService } from "src/app/order/services/internal/loading/loading.service";
import { RoutingService } from "src/app/order/services/internal/routing/routing.service";
import { ScrollService } from "src/app/order/services/internal/scroll/scroll.service";
import { isCPF } from "src/app/order/utils/cpf-util";
import { getValueWithDecimalPlacesBrazillianFormat } from "src/app/order/utils/decimal-places";
import { FdSelectConfig } from "src/app/shared/fd-form-components/fd-select/fd-select.component";
import {
  ClientInformationModel,
  SimulationModel
} from "src/app/shared/models/simulation.model";
import { FdFieldConfigs } from "src/app/shared/shared-components.module";
import { OfferQueryDataModel } from "./models/offer-query-data.model";
import { CampaignSimulator, ParamRates } from "./models/param";

@Component({
  selector: "app-fees-data",
  templateUrl: "./fees-data.component.html",
  styleUrls: ["./fees-data.component.scss"],
})
export class FeesDataComponent implements OnInit {
  @Input() clientInformation: ClientInformationModel;
  @Input() selectedCampaing: number = 0;
  @Output() feeInfoEvent = new EventEmitter<OfferQueryDataModel>();
  public formGroup: UntypedFormGroup = this.createFormGroup();
  public fields: FdFieldConfigs = this.createFields();

  public proposta: SimulationModel;
  public firstOfferQueryData: OfferQueryDataModel;
  public secondOfferQueryData: OfferQueryDataModel;
  public offerOptions: OfferQueryDataModel[] = [];
  public readonly ID_RANGE = 1;
  public readonly RECEIVING_DEADLINE = 2;
  public readonly PERSON_TYPE = "L";
  public showTitle = false;
  public hasEconomicGroup = false;
  constructor(
    private economicGroupService: EconomicGroupService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected scrollService: ScrollService,
    protected matDialog: MatDialog,
    protected routingService: RoutingService,
    private formBuilder: UntypedFormBuilder,
    private feeService: FeeService
  ) {}
  ngOnInit() {
    this.revenueRangeEdit();
  }
  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }
  private getParam(): ParamRates {
    let param = new ParamRates();
    param = {
      personType: this.clientInformation.personType,
      cnae: this.clientInformation.cnaeNumber.toString(),
      annualBillingValue: Number(this.clientInformation.billingValue),
      idCampaign: null,
      uf: this.clientInformation.state,
      receivingDeadline: null,
      chargeModelEnum: null,
      cpfCnpj: this.clientInformation.cpfCnpj,
      automaticPrepayment: false,
      hasEconomicGroup: this.hasEconomicGroup,
      institutionId: null,
      serviceContract: null,
      techReport:null,
    };
    return param;
  }
  async revenueRangeEdit() {
    if (this.formGroup.invalid) {
      return;
    }

    await this.checkEconomicGroup();
    this.feeService.getCampaigns(this.getParam()).subscribe(
      (camp) => {
        this.formControls.offerOptions.setValue("");
        (this.fields.offerOptions as FdSelectConfig).items = [];
        this.offerOptions = camp.map((c) => this.convert(c));
        this.offerOptions.sort(this.sortOfferOptions);
        (this.fields.offerOptions as FdSelectConfig).items = this.offerOptions;
        this.setSelected(this.selectedCampaing);
        this.showTitle = true;
      },
      () => {
        this.showTitle = false;
        this.dialogService.openDialog(Messages.FAILED_TO_OFFERS);
      }
    );
  }
  private sortOfferOptions = (a, b) => {
    if (a.campaignId > b.campaignId) {
      return 1;
    }
    if (a.campaignId < b.campaignId) {
      return -1;
    }
    return 0;
  };

  private async checkEconomicGroup() {
    if (isCPF(this.clientInformation.cpfCnpj)) {
      this.hasEconomicGroup = false;
      return;
    }
    this.hasEconomicGroup = false
  }

  private convert(e: CampaignSimulator): OfferQueryDataModel {
    let camp: OfferQueryDataModel = new OfferQueryDataModel();
    camp.technologyList = e.technologyList;
    camp.amountTechs = e.amountTechs;
    camp.campaignId = Number(e.idCampaign);
    camp.campaignCode = Number(e.campaignCode);
    camp.campaignName = e.description;
    camp.feeType = e.hasMdr == true ? FeeTypeEnum.MDR : FeeTypeEnum.FLEX;
    camp.isPrepaymentEnabled = e.hasMdr && e.mdrWithPrepayment;
    camp.label =
      this.getOfferPrepaymentDescription(camp) + " - " + e.description;
    camp.value = e.idCampaign;
    camp.billingRangeStart = getValueWithDecimalPlacesBrazillianFormat(
      e.minAnnualBilling as number
    );
    camp.billingRangeEnd = getValueWithDecimalPlacesBrazillianFormat(
      e.maxAnnualBilling as number
    );
    camp.monthBillingStartRounded = this.truncateValue(
      (e.minAnnualBilling as number) / 12
    );
    camp.monthBillingEndRounded = this.truncateValue(
      (e.maxAnnualBilling as number) / 12
    );
    camp.monthBillingStart = getValueWithDecimalPlacesBrazillianFormat(
      camp.monthBillingStartRounded
    );
    camp.monthBillingEnd = getValueWithDecimalPlacesBrazillianFormat(
      camp.monthBillingEndRounded
    );
    camp.hasNegotiation = e.hasNegotiation;
    camp.hasEconomicGroup = this.hasEconomicGroup;
    camp.minAnnualBilling = e.minAnnualBilling;
    camp.maxAnnualBilling = e.maxAnnualBilling;
    return camp;
  }
  public truncateValue(value: number) {
    let i = 0,
      num = value;
    while (num > 100) {
      i++;
      num = Math.floor(num / 10);
    }
    return num * Math.pow(10, i);
  }
  public getOfferPrepaymentDescription(queryData: OfferQueryDataModel) {
    return queryData.feeType === FeeTypeEnum.FLEX ||
      queryData.isPrepaymentEnabled
      ? "Com antecipação"
      : "Sem antecipação";
  }
  public setSelected(value: number) {
    const offerOptionValue = this.offerOptions.filter(
      (x) => x.value === value
    )[0];
    if (!!offerOptionValue) {
      this.offerOptions
        .filter((x) => x.value !== value)
        .forEach((x) => (x.selected = false));
      offerOptionValue.selected = true;
      this.feeInfoEvent.emit(offerOptionValue);
    }
  }
  public getBillingRangeText(selectedOffer: OfferQueryDataModel) {
    return `${selectedOffer.billingRangeStart} - ${selectedOffer.billingRangeEnd}`;
  }
  protected createFields() {
    return {
      offerOptions: {
        controlName: "offerOptions",
        items: this.offerOptions,
        messages: {
          required: "Informe uma opção",
        },
      },
    };
  }
  protected createFormGroup() {
    return this.formBuilder.group({
      offerOptions: [""],
    });
  }

  public get title(): string {
    return 'Planos disponíveis para este cliente';
  }
}
