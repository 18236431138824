export interface MessageModel {
    title: string;
    description: string;
}

export class Messages implements MessageModel {

    static readonly BACK_TO_HOME_WITH_DRAFT_CONFIRMATION = new Messages('Atenção', 'Você está retornando para a página principal. Deseja salvar o rascunho?');
    static readonly BACK_TO_HOME_CONFIRMATION = new Messages('Atenção', 'Deseja realmente retornar para a página inicial?');
    static readonly GENERAL_ERROR = new Messages('Atenção', 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde');
    static readonly CPF_CNPJ_NOT_AUTHORIZED = new Messages('Não é possível prosseguir com este CPF/CNPJ para a oferta!', '');
    static readonly CPF_CNPJ_NOT_AUTHORIZED_FOR_FIRST_PARTNER = new Messages('Não é possível prosseguir para a oferta com o CPF informado no proprietário 1', '');
    static readonly INVALID_CEP = new Messages('Atenção', 'CEP inválido');
    static readonly CEP_NOT_FOUND = new Messages('Atenção', 'CEP não encontrado');
    static readonly SERPRO_VALIDATION_ERROR = new Messages('Atenção', 'Não foi possível validar seus dados. Tente novamente mais tarde.');
    static readonly SERPRO_VALIDATION_ERROR_FOR_FIRST_PARTNER = new Messages('Atenção', 'Não foi possível validar os dados do proprietário 1. Tente novamente mais tarde.');
    static readonly BANK_VALIDATION_ERROR = new Messages('Atenção', 'Não foi possível validar seus dados. Tente novamente mais tarde.');
    static readonly CLIENT_VALIDATION_ERROR = new Messages('Atenção', 'Não foi possível validar seus dados. Tente novamente mais tarde.');
    static readonly INVALID_MINIMUM_AGE = new Messages('Atenção', 'É necessário ser maior de 18 anos para prosseguir no credenciamento');
    static readonly INVALID_MINIMUM_AGE_FOR_PARTNER = new Messages('Atenção', 'É necessário que o sócio seja maior de 18 anos para prosseguir no credenciamento');
    static readonly ADDITIONAL_DATA_NEEDED_FOR_PARTNER = new Messages('Atenção', 'É necessário completar algumas informações sobre os sócios para prosseguir. Clique no botão abaixo para preencher.');
    static readonly INVALID_BIRTH_DATE_FOR_FIRST_PARTNER = new Messages('Atenção', 'Dados do proprietário 1 inválidos. Verifique se a data de nascimento está correta.');
    static readonly INVALID_BANK = new Messages('Atenção', 'Dados bancários inválidos.');
    static readonly INVALID_BANK_DEBIT = new Messages('Atenção', 'Dados bancários de débito inválidos. Verifique a agência e conta e tente novamente');
    static readonly INVALID_BANK_CREDIT = new Messages('Atenção', 'Dados bancários de crédito inválidos. Verifique a agência e conta e tente novamente');
    static readonly INVALID_CPF_CNPJ = new Messages('Atenção', 'CNPJ/CPF nulo na receita federal. Por favor, corrigir a informação.');
    static readonly INVALID_CPF_CNPJ_FOR_FIRST_PARTNER = new Messages('Atenção', 'CPF do proprietário 1 nulo na receita federal. Por favor, corrigir a informação.');
    static readonly MAX_DOCUMENT_CALL_LIMIT_EXCEEDED = new Messages('Atenção', 'Tentativas de consulta de CPF/CNPJ esgotadas. Por favor, inicie um novo credenciamento');
    static readonly FAILED_TO_GET_TECHNOLOGIES = new Messages('Atenção', 'Não foi possível carregar as tecnologias. Tente novamente mais tarde');
    static readonly FAILED_TO_GET_PROPOSAL_DATA = new Messages('Indisponibilidade', 'Serviço indisponível. Após 30 minutos tente novamente.');
    static readonly NOT_FOUND_MCC_FOR_CNAE = new Messages('Atenção', 'Não existem taxas para a campanha, range e cnae escolhido.');
    static readonly MAX_PRODUCT_LIMIT_REACHED = new Messages('Atenção', 'A quantidade máxima de produtos foi atingida');
    static readonly DUPLICATED_PRODUCTS = new Messages('Atenção', 'Não é possível enviar produtos duplicados');
    static readonly COM_AND_OTHERS_IN_THE_SAME_PROPOSAL = new Messages('Atenção', 'Não é possível enviar uma tecnologia E-Commerce junto com outro produto de Cartão Presente na mesma proposta');
    static readonly MAX_PORCENT_LIMIT_REACHED = new Messages('Atenção', 'A soma da porcentagem de participação dos sócios/proprietários não pode ser superior a 100%.');
    static readonly MAX_PARTNER_LIMIT_REACHED = new Messages('Atenção', 'Não é possível adicionar mais sócios.');
    static readonly PARTNER_PERCENT_NOT_REACHED = new Messages('Atenção', 'A porcentagem de participação deve totalizar 100%');
    static readonly ENTERPRISE_PARTNER_PERCENT_NOT_REACHED = new Messages('Atenção', 'A porcentagem de participação dos sócios-empresa deve totalizar 100%');
    static readonly UNABLE_TO_OPEN_PRICE_EDIT_DIALOG = new Messages('Atenção', 'Selecione uma tecnologia');
    static readonly DUPLICATED_PARTNER_CPF = new Messages('Atenção', `Não podem existir CPF's duplicados para os sócios`);
    static readonly DUPLICATED_PARTNER_CNPJ = new Messages('Atenção', `Não podem existir CNPJ's duplicados para os sócios`);
    static readonly DUPLICATED_BRANCH_CNPJ = new Messages('Atenção', `Não podem existir CNPJ's duplicados para as filiais`);
    static readonly FAILED_TO_GET_RANGE = new Messages('Atenção', 'Não foi possível carregar o range de preço. Tente novamente mais tarde');
    static readonly FAILED_TO_GET_MDR_FEES = new Messages('Atenção', 'Não foi possível carregar as taxas MDR. Tente novamente mais tarde');
    static readonly FAILED_TO_GET_CONSTITUTION_FORMS = new Messages('Atenção', 'Não foi possível carregar as formas de constituição. Tente novamente mais tarde');
    static readonly FAILED_TO_GET_MDR_FLEX_FEES = new Messages('Atenção', 'Não foi possível carregar as taxas MDR Flex. Tente novamente mais tarde');
    static readonly FAILED_TO_GET_MDR_FLEX_FACTORS = new Messages('Atenção', 'Não foi possível carregar os fatores das taxas MDR Flex. Tente novamente mais tarde');
    static readonly NO_FACTORS_AVAILABLE = new Messages('Atenção', 'Não foram encontrados os fatores das taxas MDR Flex');
    static readonly FAILED_TO_GET_PREPAYMENT_FEE = new Messages('Atenção', 'Não foi possível carregar a taxa de antecipação. Tente novamente mais tarde');
    static readonly FAILED_TO_CALCULATE_FEES = new Messages('Atenção', 'Não foi possível calcular as taxas. Tente novamente mais tarde');
    static readonly FAILED_TO_VALIDATE_BANK_DATA = new Messages('Atenção', "Não foi possível validar seus dados bancários. Tente novamente mais tarde");
    static readonly PROPOSAL_SUBMIT_ERROR = new Messages('Atenção', 'Não foi possível enviar a proposta. Tente novamente mais tarde');
    static readonly PROPOSAL_SUBMIT_ERROR_WITH_DETAILS_BUTTON = new Messages('Atenção', 'Não foi possível enviar a proposta. Clique no botão abaixo para mais detalhes');
    static readonly NO_DATA_RETURNED_ON_PROPOSAL_SUBMIT = new Messages('Atenção', 'Número da proposta não retornado.');
    static readonly CAMPAIGN_NOT_FOUND = new Messages('Atenção', 'Erro ao obter lista de campanhas. Tente novamente mais tarde.')
    static readonly PAYMENT_SUBMIT_ERROR = new Messages('Atenção', 'Não foi possível enviar o pagamento. Tente novamente mais tarde.')
    static readonly SERVICE_CONTRACT_CONFIG_NOT_SET = new Messages('Atenção', 'Este service contract não está devidamente configurado para efetuar o credenciamento,')
    static readonly MDR_OR_MDR_FLEX_CONFIG_NOT_SET = new Messages('Atenção', 'Este service contract não possui nenhum modelo de cobrança (MDR ou MDR Flex) habilitado.')

    static readonly FAILED_TO_GET_BANK_LIST = new Messages('Indisponibilidade', 'Serviço indisponível. Após 30 minutos tente novamente')
    static readonly SELECT_ACCOUNT_TYPE = new Messages('Atenção', 'Selecione o tipo de conta.')
    static readonly LOGIN_ERROR = new Messages('Atenção', "Não foi possível efetuar login. Tente novamente mais tarde")
    static readonly NO_TOKEN_DATA = new Messages('Atenção', "Não foi possível efetuar login. Tente novamente mais tarde")
    static readonly NO_LOGIN_DATA = new Messages('Atenção', "Insira usuário e senha para continuar")
    static readonly NO_TECHNOLOGIES_FOUND = new Messages('Atenção', "Nenhuma tecnologia disponível")
    static readonly SESSION_TIMED_OUT = new Messages('Atenção', "Sessão expirada. Efetue o login novamente");
    static readonly UNWANTED_CNAE_SELECTED = new Messages('Atenção', 'Não temos oferta para esse CNAE.')

    static readonly MISSING_ECOMMERCE_SALES_CHANNEL = new Messages('Atenção', 'É necessário selecionar ao menos um canal de venda.')
    static readonly MISSING_FUNCTIONALITY = new Messages('Atenção', 'É necessário selecionar ao menos uma funcionalidade.')
    static readonly MISSING_PROSPECTION_TYPE = new Messages('Atenção', 'É necessário selecionar ao menos uma opção relacionada a oportunidade de negócio')
    static readonly PERCENT_MUST_BE_LOWER_THAN_100 = new Messages('Atenção', 'A somatória não pode ultrapassar 100%')
    static readonly EXPIRED_PROPOSAL = new Messages('Atenção', 'A proposta que estava em andamento expirou. Favor iniciar um novo credenciamento')
    static readonly START_NEW_PROPOSAL = new Messages('Atenção', 'Ao iniciar uma nova proposta, a proposta não finalizada será descartada. Deseja continuar?')
    static readonly FEES_LOWER_THAN_ALLOWED = new Messages('Atenção', 'Os valores de taxas selecionadas na proposta não são permitidas. Por gentileza, ajuste os valores e envie a proposta novamente')
    static readonly TECHNOLOGIES_PRICES_LOWER_THAN_ALLOWED = new Messages('Atenção', 'Os valores de aluguel/venda presentes na proposta não são permitidos. Por gentileza, ajuste os valores e envie a proposta novamente')
    static readonly MISSING_TEF_CONNECTION_TYPE = new Messages('Atenção', 'É necessário selecionar ao menos um tipo de conexão')
    static readonly MISSING_TEF_PROVIDER_TYPE = new Messages('Atenção', 'É necessário selecionar ao menos um provedor')
    static readonly MISSING_FIRST_DATA_PIN_PAD = new Messages('Atenção', 'É necessário selecionar ao menos uma opção')
    static readonly INVALID_SERPRO_SITUATION = new Messages('Atenção', 'Situação cadastral do CPF diferente de regular')
    static readonly PERCENT_MUST_BE_100 = new Messages('Atenção', 'A somatória deve totalizar 100%')
    static readonly ALGAR_CHIP_NOT_FOUND = new Messages('Atenção', 'Chip Algar não encontrado')
    static readonly ONLY_ECOMMERCE_PROPOSAL = new Messages('Atenção', 'Só é possível prosseguir com a criação de uma proposta E-Commerce. Deseja seguir?')
    static readonly ERROR_WHEN_FILLING_POS_AMOUNT = new Messages('Atenção', 'Erro ao obter quantidade de tecnologias. Tente novamente mais tarde')
    static readonly ERROR_WHEN_VALIDATING_POS_AMOUNT = new Messages('Atenção', 'Erro ao validar quantidade de tecnologias. Tente novamente mais tarde')
    static readonly UNABLE_TO_PROCEED_POS_AMOUNT = new Messages('Atenção', 'Não é possível prosseguir para este cliente com a quantidade de produtos POS selecionada')
    static readonly NO_BRANCH_OFFICE_INFORMED = new Messages('Atenção', 'Informe ao menos uma filial')
    static readonly NO_POS_AMOUNT = new Messages('Atenção', 'Não há tecnologias POS disponíveis para este cliente')

    static readonly PROF_DOC_EXPIRED = new Messages('Atenção', 'O documento está com data de validade vencida')
    static readonly PURCHASE_DATE_GREATER_THAN_TODAY = new Messages('Atenção', 'Data de emissão é superior à data atual.')
    static readonly PURCHASE_DATE_GREATER_THAN_90_DAYS = new Messages('Atenção', 'Data de emissão da nota maior que 90 dias. Não é prossível prosseguir com a inclusão.')
    static readonly INVOICE_NUMBER_EXIST = new Messages('Atenção', 'Número de nota fiscal já incluída')
    static readonly MAX_INVOICES = new Messages('Atenção', 'A proposta deverá conter no máximo 3 notas fiscais')
    static readonly MAX_TOTAL_VALUE_INVOICES = new Messages('Atenção', 'O somatório das notas deverá ser maior ou igual a R$ 400,00')
    static readonly INVOICE_VALUE_ZERO = new Messages('Atenção', 'O valor da nota fiscal deverá ser maior que zero')
    static readonly CONFIG_PROFESSIONAL_LOAD_FAIL = new Messages('Atenção', 'Não foi possível carregar as opções LICENÇA PROFISSIONAL')
    static readonly CONFIG_PROPOSAL_LOAD_FAIL = new Messages('Atenção', 'Falha ao carregar as configurações das propostas')
    static readonly ANNUAL_REVENUE_MIN = new Messages('Atenção', 'Valor de faturamento anual deverá ser maior que um')
    static readonly ANNUAL_REVENUE_INVALID = new Messages('Atenção', 'Valor de faturamento anual não confere com o a faixa escolhida')
    static readonly AVERANGE_TICKET = new Messages('Atenção', 'Valor de ticket médio deverá ser maior que um')
    static readonly ANNUAL_VOLUME_SALES_CARD_GROUP = new Messages('Atenção', 'Valor do faturamento total de cartão do Grupo deverá ser maior que um')
    static readonly ANNUAL_REVENUE_BIGGER_THAN_CARD_GROUP = new Messages('Atenção', 'O Valor do faturamento anual deverá ser menor ou igual ao faturamento do Grupo')
    static readonly AVERANGE_TICKET_BIGGER_THAN_ANNUAL_REVENUE = new Messages('Atenção', 'Valor de ticket médio deverá ser menor que o faturamento anual')

    static readonly TYPE_SERVICE_INVALID_SIPAG = new Messages('Atenção', 'Cadastro já existente com mesma tecnologia! Não é possível prosseguir com o credenciamento')
    static readonly RATES_INVALID_SIPAG = new Messages('Atenção', 'Cadastro cliente Sipag já existente com taxa menor/maior que informada! Não é possível prosseguir com o credenciamento')
    static readonly CPF_CNPJ_NOT_AUTHORIZED_CHANNEL_SIPAG = new Messages('Não é possível prosseguir com este CPF/CNPJ para a oferta pela validação por canal!', '');
    static readonly INVALID_INVOICE_DATE = new Messages('Atenção', 'Data de emissão da nota inválida');
    static readonly PREPAYMENT_CHECK_ERROR = new Messages('Atenção', 'Erro ao realizar validação para taxas para antecipação');
    static readonly PREPAYMENT_VALIDATION_NOT_FOUND_RATE = new Messages('Atenção', 'Não foi encontrado taxa de antecipação para o MCC do CNAE informado e campanha e range de Faturamento selecionados');
    static readonly DRAFT_SAVE_ERROR = new Messages('Atenção', 'Ocorreu um erro ao salvar o rascunho da sua proposta. Aguarde alguns segundos e tente prosseguir novamente');
    static readonly DRAFT_LIMIT_EXCEEDED = new Messages('Atenção', 'A quantidade máxima de rascunhos foi atingida. Para prosseguir, exclua um dos rascunhos existentes.');
    static readonly GENERIC_ERROR = new Messages('Atenção', 'Ocorreu um erro ao processar sua requisição, clique em detalhes para mensagem detalhada do erro');
    static readonly OPEN_DATE_LEGAL_PERSON_ERROR = new Messages('Atenção', 'A data de fundação deverá ser maior que a de hoje');
    static readonly ERROR_WHEN_GETTING_OPTIN_TERM = new Messages("Atenção", "Ocorreu um erro ao obter os termos de aceite OPT-IN. Tente novamente mais tarde");

    static readonly DRAFT_DELETE_CONFIRMATION = new Messages("Atenção", "Deseja realmente excluir este rascunho? Esta ação não poderá ser desfeita!");
    static readonly EDIT_SAVE_SUCCESS = new Messages("Atenção", "Alteração realizada com sucesso");
    static readonly EDIT_SAVE_ERROR = new Messages("Atenção", "Houve um erro ao realizar a alteração");

    static readonly PROPOSAL_DUPLICITY_ERROR = new Messages("Atenção", "Já existe um credenciamento em andamento com a(s) mesma(s) tecnologia(s). Favor aguardar a finalização do(s) processo(s) para criação de uma nova proposta.");
    static readonly ERROR_WHEN_CHECK_PROPOSAL_DUPLICITY = new Messages("Atenção", "Erro ao validar duplicidade da proposta. Tente novamente mais tarde.");
    static readonly INCORRECT_CNAE = new Messages("Atenção", "O CNAE informado não corresponde a nenhum dos CNAE's válidos para este CNPJ. Clique no botão abaixo para corrigir a informação.");

    static readonly SERPRO_IRREGULAR_DOCUMENT_SITUATION = new Messages("Atenção", "O CPF informado está incorreto ou não é válido. Clique no botão abaixo para corrigir a informação.");
    static readonly SERPRO_IRREGULAR_CNPJ_SITUATION = new Messages("Atenção", "O CNPJ informado está incorreto ou não é válido. Clique no botão abaixo para corrigir a informação.");
    static readonly SERPRO_INVALID_NAME = new Messages("Atenção", "O nome informado na tela de Dados Cadastrais está incorreto. Clique no botão abaixo para corrigir a informação.");
    static readonly SERPRO_INVALID_SOCIAL_REASON = new Messages("Atenção", "A razão social informada está incorreta. Clique no botão abaixo para corrigir a informação.");
    static readonly SERPRO_INVALID_OPEN_DATE = new Messages("Atenção", "A data de abertura informada na tela de Dados Cadastrais está incorreta. Clique no botão abaixo para corrigir a informação.");
    static readonly SERPRO_INVALID_BIRTH_DATE = new Messages("Atenção", "A data de nascimento informada na tela de Dados Cadastrais está incorreta. Clique no botão abaixo para corrigir a informação.");
    static readonly SERPRO_INVALID_PARTNER_DATA = new Messages("Atenção", "Os dados do primeiro sócio informado estão inválidos. Clique no botão abaixo para corrigir a informação.");
    static readonly SIMULATOR_NOT_AVAILABLE_FOR_SPECIFIED_LOGIN = new Messages("Atenção", "O simulador não está disponível para a hierarquia associada ao login informado");
    static readonly SIMULATION_SAVE_SUCCESS = new Messages("Atenção", "Simulação salva com sucesso");
    static readonly SIMULATION_SAVE_ERROR = new Messages("Atenção", "Houve um erro ao salvar a simulação. Tente novamente mais tarde");
    static readonly SIMULATION_CNAE_LOTTERY_ERROR = new Messages("Atenção", "Para credenciamento de Lotéricas utilize o plano 24 no SICRD. Consulte as condições comerciais no material de apoio, disponível no redevarejo.caixa – Ações Táticas");
    static readonly PROPOSAL_SUBMIT_ERROR_CNAE_SERVICE_FAILED = new Messages('Atenção', 'As configurações do contrato não estão definidas corretamente, solicite ajustes');

    static readonly EMAIL_NOT_AUTHORIZED = new Messages('Não é possível prosseguir com este EMAIL para a oferta!', '');
    static readonly PHONE_NOT_AUTHORIZED = new Messages('Não é possível prosseguir com este Telefone para a oferta!', '');
    static readonly GENERAL_ERROR_BLACKLIST_API = new Messages('Atenção', 'Houve um erro ao validar dados na blacklist. Tente novamente mais tarde');

    static readonly FAIL_TO_DECRYPT = new Messages('Atenção', 'Não foi possível carregar as informações de CNPJ / CNAE ou Estado. Tente novamente pelo SICRD.');
    static readonly INVALID_SIMULATION_TECHS = new Messages('Atenção', 'Informe a tecnologia e a quantidade.');

    static readonly UNWANTED_CNAE = new Messages(
      'Simulação não permitida',
      'CNAE não elegível ao Credenciamento.<br/><br/>\
      Consulte os Ramos e CNAEs elegíveis ao credenciamento no manual normativo – CO 011.<br/><br/>\
      Demais informações disponíveis nos materiais de apoio no redevarejo.caixa.');

    public static readonly SIMULATION_SEND_ERROR = new Messages(
        'Atenção',
        'Houve um erro ao enviar a negociação. Tente novamente mais tarde'
      );

    public static readonly TOTAL_REVENUE_PERCENT = new Messages(
        'Atenção',
        'A distribuição do faturamento por produto não poderá ser diferente de 100%'
      );
    public static readonly TOTAL_REQUESTED_EXCEPTIONS = new Messages(
        'Atenção',
        'O total de insenções não poderá ser maior que a quantidade de máquinas'
      );

    public static readonly FAILED_TO_GET_REPORT = new Messages(
        'Atenção',
        'Falha ao gerar relatório/Documento'
    );

    public static readonly FAILED_TO_SAVE_SIMULATION = new Messages(
      'Atenção',
      'Não foi possível armazenar os dados da simulação'
    )

    public static readonly FAILED_TO_OFFERS = new Messages(
        'Atenção',
        'Não há oferta para este cliente.'
    );

    public static readonly NOT_FOUND_ECONOMIC_CONV_GROUP = new Messages(
      'Atenção',
      'Grupo Comercial Azulzinha por <br/>\
       Conveniência não encontrado'
    );

    public static readonly ONE_FIELD_REQUIRED = new Messages(
      'Atenção',
      'Ao menos um campo deve ser preenchido para a pesquisa.'
    );

    public static readonly ERROR_SEARCH = new Messages(
      'Atenção',
      'Erro na pesquisa!'
    );

    public static readonly BILLING_VALUE_EQUALS_OR_ABOVE_THIRTY_MILLIONS = new Messages(
      'Atenção',
      'Clientes com Faturamento acima de<br/>\
       R$30.000.000,00 devem seguir pelo<br/>\
       fluxo de Atacado.<br/><br/>\
       Solicite a precificação no<br/>\
       azulzinha.caixa'
    );

    public static readonly BILLING_VALUE_LESS_THAN_OR_EQUALS_30K = new Messages(
      'Atenção',
      'Grupos com faturamento menor ou<br/>\
       igual a R$30.000,00/mês não são<br/>\
       elegíveis para formação de Grupo Comercial Azulzinha.<br/><br/>'
    );

    public static readonly SUCCESSFUL_CONTRACT_DOWNLOAD = new Messages(
      'Atenção',
      'Download do contrato realizado com sucesso. <br/><br/>\
       Solicite a assinatura de todos os participantes antes de seguir para a criação do grupo.'
    );

    public static readonly REQUEST_ERROR = new Messages('Atenção', 'Ocorreu um erro ao realizar a requisição');
    static readonly DATA_REQUIRED_ERROR_GROUP = new Messages('Não foi possivel criar grupo', 'Verifique o preenchimento dos campos.');
    static readonly DATA_REQUIRED_ERROR_GROUP_EDIT = new Messages('Não foi possivel editar grupo', 'Verifique o preenchimento dos campos.');
    static readonly ECONOMIC_GROUP_CONV_SAVE = new Messages('Atenção', 'Solicitação de Grupo Comercial Azulzinha realizada com sucesso. Você receberá um email com a confirmação.');
    static readonly ECONOMIC_GROUP_CONV_EDIT = new Messages('Atenção', 'Edição de Grupo Comercial Azulzinha realizada com sucesso.');

    public static readonly INFORM_AT_LEAST_ONE_FIELD_ECONOMIC_GROUP_CONV_SEARCH = new Messages('Atenção', 'Ao menos um campo deve ser preenchido para consulta do grupo comercial azulzinha por conveniência');
    public static readonly ECONOMIC_GROUP_CONV_NOT_FOUND = new Messages('Atenção', 'Grupo Comercial Azulzinha por Conveniência não encontrado');

    static readonly EMAIL_INVALID = new Messages('Atenção', 'EMAIL inválido!');
    static readonly SOLICITATION_SUCCESS =  new Messages('Atenção', 'Solicitação de negociação enviada com sucesso. <br></br> Você receberá a Contra Proposta por e-mail em até 2 dias úteis e para efetivar a contratação deverá acessar o SICRD > NOVA PROPOSTA > CONDIÇÕES COMERCIAIS > CONTRA PROPOSTA');
    static readonly ECONOMIC_GROUP_SIMULATION_DETAILS_ERROR = new Messages('Atenção', 'Não foi possível consultar detalhes do grupo');
    static readonly GROUP_PRICE_SAVE_ERROR = new Messages('Atenção', 'Houve um erro ao salvar a precificação. Tente novamente mais tarde');
    static readonly CREATE_ECONOMIC_GROUP_SIMULATION_ERROR = new Messages('Atenção', 'Houve um erro ao tentar criar grupo comercial azulzinha. Tente novamente mais tarde');
    static readonly CREATE_ECONOMIC_GROUP_SUCCESS = new Messages('Grupo criado com sucesso.', 'As condições comerciais serão atualizadas de acordo com a negociação.');
    static readonly LIST_SIMULATION_ERROR = new Messages('Atenção.', 'Erro ao consultar simulações.');
    static readonly CONTRACT_SIMULATION_ERROR = new Messages('Atenção.', 'Erro ao gerar contrato.');
    static readonly MCC_AND_CNAE_SEARCH_VALIDATION = new Messages('Atenção.', 'É preciso informar o CNAE ou o MCC para realizar a consulta.');

    title: string;
    description: string;

    public constructor(private titleParam: string, private descriptionParam: string) {
        this.title = titleParam;
        this.description = descriptionParam;
    }

    toString() {
        return this.title;
    }
}
