import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SimulatorConfigService } from '../services/external/simulator-config/simulator-config.service';
import { ClientInformation, EconomicGroupItems } from 'src/app/shared/side-nav/side-navs/side-nav.items';
import { SidenavService } from 'src/app/shared/side-nav/side-nav.service';
import { ClientInformationSideNavComponent } from 'src/app/shared/side-nav/side-navs/client-information-side-nav/client-information-side-nav.component';
import { EconomicGroupSideNavComponent } from 'src/app/shared/side-nav/side-navs/economic-group-side-nav/economic-group-side-nav.component';
import { DefaultSideNavComponent } from 'src/app/shared/side-nav/side-navs/default-side-nav.component';

@Injectable({
  providedIn: 'root'
})
export class OrderStepGuard implements CanActivate {

  public validated$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private simulatorConfigService: SimulatorConfigService,
    public sidenavService: SidenavService
  ) {

  }

  async canActivate(currentRoute: ActivatedRouteSnapshot) {
    let isEnabled = await this.isEnabledEconomicGroup(); 
    this.activeSideMenu(currentRoute, isEnabled);
    await this.check(currentRoute, isEnabled);
    return true;
  }

  async check(currentRoute: ActivatedRouteSnapshot, isEnabled: boolean){
    if(currentRoute.url[1]){
      if(!isEnabled){
        let hasAccess = true;
        for(let i = 0;  i < EconomicGroupItems.length; i++){
            if(currentRoute.url[1].path === EconomicGroupItems[i].path){
                hasAccess = false;
                break;
            }
        }
        if(!hasAccess){
          this.router.navigate([ClientInformation.link]);
        }
      }
    }
  }
  
  async isEnabledEconomicGroup() {
    let isEnabled = await this.simulatorConfigService.getAblePriceSimulator(); 
    return isEnabled;
  }
  
  activeSideMenu(currentRoute: ActivatedRouteSnapshot, isEnabled: boolean) {
    if(!isEnabled){
      this.sidenavService.push(DefaultSideNavComponent);
      return;
    }
    if(currentRoute.url[1]){
      if(currentRoute.url[1].path === ClientInformation.path) {
        this.sidenavService.push(ClientInformationSideNavComponent);
      } else {
        this.sidenavService.push(EconomicGroupSideNavComponent);
      }
    }
  }
  

}
