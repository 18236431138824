<div class="fd-business-data-detail__card-container width45" *ngIf="data">
  <table mat-table [dataSource]="dataSource" class="fd-table">
    <!-- ID Column -->

    <ng-container matColumnDef="annualBilling" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Faturamento/Mês</th>
      <td mat-cell *matCellDef="let rowElement">{{ rowElement.annualBilling | currency: 'BRL' }}</td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="suggestedAutoPrepayment" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header align-center">Taxa/Mês</th>
      <td class="align-center" mat-cell *matCellDef="let rowElement">{{ formatPercentage(rowElement.suggestedAutoPrepayment)}}</td>
    </ng-container>

    <!-- Color Column -->
    <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
