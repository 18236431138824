import { GenericMdrFeeModel } from "src/app/order/components/simulation-data/simulation-table/models/generic-mdr-fee.model";

export interface SimulationModel {
  serviceContract?: number;
  channel?: string;
  subChannel?: string;
  agentChannel?: string;
  institution?: string;
  channelType?: string;
  clientInformation?: ClientInformationModel;
  simulationOfferInformation?: SimulationOfferInformationModel;
}

export class ClientInformationModel {
  cpfCnpj: string;
  billingValue: string;
  cnaeNumber: number;
  state: string;
  personType: string;

  /**
   *
   */
  constructor() {
    this.cpfCnpj = '';
    this.billingValue = '';
    this.cnaeNumber = 0;
    this.state = '';
    this.personType = '';
  }

}

export class SimulationOfferInformationModel {
  fees: SimulationFeesModel[];
  status: SimulationOfferStatusEnum;
  order: number;
}

export class SimulationFeesModel {
  sentPrepaymentFee?: number;
  suggestedPrepaymentFee?: number;
  minimumFactorFee?: number;
  suggestedFactorFee?: number;
  factorType?: number;
  serviceFees: SimulationServiceFeesModel[];
}

export enum SimulationOfferStatusEnum {
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED"
}

export class SimulationServiceFeesModel {
  sentMdrFee?: number;
  minimumMdrFee?: number;
  suggestedMdrFee?: number;
  sentMdrFlexFee?: number;
  minimumMdrFlexFee?: number;
  suggestedMdrFlexFee?: number;
  idService: string;
  modality: string;
  transactionDescription: string;
}

export class SimulationInformationModel {
  simulationCod: number;
  annualBilling: string;
  institution: string;
  serviceContract: number;
  cnae: number;
  cpfCnpj: string;
  uf: string;
  managerCod: string;
  campaign: CampaignSimulationModel;
  mdrRates: GenericMdrFeeModel[];
  level: number;
  simulationPhrase: SimulationPhraseModel = new SimulationPhraseModel();
  simulationTechnologyDTO: SimulationTechnologyModel = new SimulationTechnologyModel();

  constructor() {
    this.simulationCod = null;
  }
}

export class CampaignSimulationModel {
  campaignCod: Number;
  suggestedAutoPrepayment: number;
  suggestedFactorFee1: number;
  suggestedFactorFee2: number;
}

export class SimulationPhraseModel{
  pricingTechnologyPhrase: string;
  exemptionPolicyPhrase: string;
  disclaimerPhrase: string;
  disclaimerTechPhrase: string;
  offerMessage: string;
  exemptionPolicyPhraseFactorFlex:string;
}

export class SimulationTechnologyModel{
  technologyName: string;
  technologyAmount: number;
  technologyRent: number;
  serviceId: string;
}
