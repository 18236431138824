import { ValidatorFn, AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { CompareTypeEnum } from '../enums/compare-type.enum';

function clearCompareErrors(control: AbstractControl) {
  if (control.hasError('greaterThan')) {
    delete control.errors['greaterThan'];
    control.updateValueAndValidity();
  }
  if (control.hasError('lessThan')) {
    delete control.errors['lessThan'];
    control.updateValueAndValidity();
  }
  if (control.hasError('greaterThanOrEqual')) {
    delete control.errors['greaterThanOrEqual'];
    control.updateValueAndValidity();
  }
  if (control.hasError('lessThanOrEqual')) {
    delete control.errors['lessThanOrEqual'];
    control.updateValueAndValidity();
  }
  if (control.hasError('equal')) {
    delete control.errors['equal'];
    control.updateValueAndValidity();
  }
}

export function CompareValidator(compareControl: AbstractControl, compareTypeEnum: CompareTypeEnum): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (!isControlsValids(compareControl, control)) {
      return {};
    }
    if (compareTypeEnum === CompareTypeEnum.GREATER_THAN
          && control.value < compareControl.value) {
       
        return { greaterThan: true };

    } else if (compareTypeEnum === CompareTypeEnum.LESS_THAN 
      && control.value > compareControl.value) {
        return { lessThan: true };
      
    } else if (compareTypeEnum === CompareTypeEnum.GREATER_THAN_OR_EQUAL &&
      control.value <= compareControl.value) {
        return { greaterThanOrEqual: true };
      
    } else if (compareTypeEnum === CompareTypeEnum.LESS_THAN_OR_EQUAL
      && control.value >= compareControl.value) {
        return { lessThanOrEqual: true };
      
    } else if (compareTypeEnum === CompareTypeEnum.EQUAL && 
      control.value !== compareControl.value) {
        return { equal: true };
      
    }
    clearCompareErrors(compareControl);
    return {};
  };
}

function isControlsValids(compareControl: AbstractControl, control: AbstractControl) {
  return (compareControl &&
    compareControl.value &&
    control &&
    control.value)
}

export function EqualsSumValidator(compareControl: AbstractControl, values: AbstractControl[],
                                   equalsSum: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!compareControl || !compareControl.value || !control || !control.value || !checkIfFieldsWereDirty(values)) {
      return {};
    }

    let sum = 0;
    values.forEach((v) => {
      sum = sum + Number(v.value);
    });

    if (isNaN(sum) === false && sum !== equalsSum) {
      return { equalsSumValues: true };
    } else if (isNaN(sum) === false && sum === equalsSum) {
      for (const v of values) {
        if (v.hasError('equalsSumValues')) {
          delete v.errors.equalsSumValues;
          v.updateValueAndValidity();
        }
      }
    }

    return {};
  };
}

function checkIfFieldsWereDirty(values: AbstractControl[]): boolean {
  let allDirty = true;
  values.forEach(v => {
    if (!v.dirty) {
      allDirty = false;
    }
  });
  return allDirty;
}

export function CompareValidatorNumberConvert(compareControl: AbstractControl, compareTypeEnum: CompareTypeEnum): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!isControlsValids(compareControl, control)) {
      return {};
    }

    if (compareTypeEnum === CompareTypeEnum.GREATER_THAN 
      && Number(control.value) < Number(compareControl.value)) {
        return { greaterThan: true };
      
    } else if (compareTypeEnum === CompareTypeEnum.LESS_THAN && 
      Number(control.value) > Number(compareControl.value)) {
        return { lessThan: true };
      
    } else if (compareTypeEnum === CompareTypeEnum.GREATER_THAN_OR_EQUAL && 
      Number(control.value) < Number(compareControl.value)) {
        return { greaterThanOrEqual: true };
      
    } else if (compareTypeEnum === CompareTypeEnum.LESS_THAN_OR_EQUAL &&
      Number(control.value) < Number(compareControl.value)) {
        return { lessThanOrEqual: true };
      
    } else if (compareTypeEnum === CompareTypeEnum.EQUAL && 
      Number(control.value) < Number(compareControl.value)) {
        return { equal: true };
      
    }
    clearCompareErrors(compareControl);
    return {};
  };
}
