import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ExemptionFeeDataModel } from '../models/economical-group-simulation.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-exemption-policy-table',
  templateUrl: './exemption-policy-table.component.html',
  styleUrls: ['./exemption-policy-table.component.scss']
})
export class ExemptionPolicyTableComponent implements OnChanges {
  @Input()
  public data: ExemptionFeeDataModel[];

  @Input()
  public description: string;

  public dataSource: MatTableDataSource<ExemptionFeeDataModel> = new MatTableDataSource();
  public displayedColumns = ['faturamentoMes', 'isencaoAluguel', 'maquinas'];
  

  constructor(private decimalPipe: DecimalPipe) {}


  ngOnInit(): void {
    this.dataSource.data = this.data;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('data' in changes) {
      this.dataSource.data = this.data;
    }
  }

  public formatPercentage(number: number) {
    if(number == 100){
      let numberFormatted = number.toString();
      if(numberFormatted != null && numberFormatted != '0') {
        return numberFormatted.toString().concat(' %');
      }
    }

    let numberFormatted = this.decimalPipe.transform(number, '1.2', 'pt');
    if(numberFormatted != null && numberFormatted != '0') {
      return numberFormatted.toString().concat(' %');
    }
    return '--';
  }

  

}
