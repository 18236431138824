import { Component, OnDestroy, Input, ElementRef, Optional, Self, OnInit, Renderer2, Output, EventEmitter, HostBinding, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, UntypedFormBuilder, NgControl, UntypedFormGroup, AbstractControl, FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { tap, takeWhile } from 'rxjs/internal/operators';
import { takeUntil } from 'rxjs/internal/operators';
import { FocusMonitor } from '@angular/cdk/a11y';
import { FdFieldBaseComponent } from '../fd-field/fd-field.base-component';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { MatTooltip } from '@angular/material/tooltip';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { Messages } from 'src/app/order/messages/order.messages';

@Component({
  selector: 'fd-input',
  templateUrl: 'fd-input.component.html',
  styleUrls: ['fd-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: FdInputComponent }],
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
    '[attr.aria-describedby]': 'describedBy',
  }
})

export class FdInputComponent extends FdFieldBaseComponent implements ControlValueAccessor, OnInit, OnDestroy {
  static nextId = 0;
  private readonly REQUIRED_VALIDATOR_KEY = 'required';
  alive = true;

  @ViewChild('tooltip', { static: false }) tooltip: MatTooltip;

  @Input()
  field: FdInputConfig;

  @Output()
  input = new EventEmitter<string>();

  @Input()
  parentForm: UntypedFormGroup;

  @Output()
  blur = new EventEmitter<string>();

  @HostBinding('class.fd-field--invalid')
  get invalid(): boolean {
    this.cdr.detectChanges();
    return this.hasError;
  }

  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'fd-input';
  describedBy = '';
  onChange = (_: any) => { };
  onTouched = () => { };

  get relatedFormControl(): AbstractControl {
    return this.parentForm.get(this.field.controlName);
  }

  get hasError() {
    return this.relatedFormControl && this.relatedFormControl.errors != null;
  }

  get errorMessages() {
    return Object.keys(this.field.messages)
      .filter(val => this.relatedFormControl.errors[val])
      .map(key => this.field.messages[key]);
  }

  showTooltipAndStopPropagation($event: any) {
    this.tooltip.toggle();
    event.stopImmediatePropagation();
  }

  get shouldLabelFloat() { return this.focused }

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  constructor(
    formBuilder: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private focusMonitor: FocusMonitor,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl) {

    super();

    focusMonitor.monitor(elementRef, true)
      .pipe(takeWhile(() => this.alive))
      .subscribe(origin => {
        if (this.focused && !origin) {
          this.onTouched();
        }
        this.focused = !!origin;
        this.stateChanges.next();
      });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  unmask(control: AbstractControl, maskCharsReplace: RegExp): string {
    let unmaskedValue = '';
    if (!control) { return; }
    control.valueChanges
      .pipe(takeWhile(() => this.alive))
      .pipe(
        tap((value: string = '') => {
          if (value) {
            unmaskedValue = value.replace(maskCharsReplace, '').trim();
            control.setValue(unmaskedValue, { emitEvent: false, emitModelToViewChange: false });
          }
        }))
      .pipe(takeWhile(() => this.alive))
      .subscribe();

    return unmaskedValue;
  }

  ngOnDestroy() {
    this.alive = false;
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  async ngOnInit() {
    if (this.field && this.field.disabled) {
      this.relatedFormControl.disable();
    }

    if (this.field && this.field.mask) {
      this.unmask(this.relatedFormControl, this.field.maskCharsReplace);
    }
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  get required(): boolean {
    const validator = this.relatedFormControl.validator({} as AbstractControl);

    return validator && validator.required;
  }

  get hasIcon(): boolean {
    return this.field && !!this.field.iconHTML;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.elementRef.nativeElement.querySelector('input')!.focus();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  handleBlur(event: Event): void {
    if (this.blur) {
      const inputValue = (event.target as HTMLInputElement).value;
      if (this.field.isNumber) {
        this.blur.emit(inputValue.replace(/\D+/g, ''));
      }
      this.blur.emit(inputValue);
    }
    event.stopPropagation();
  }

  handleChange(event: Event): void {
    if (this.input) {
      const inputValue = (event.target as HTMLInputElement).value;
      this.input.emit(inputValue);
    }
    event.stopPropagation();
  }

}




export type Mask = (RegExp | string)[];

export enum InputType {
  DATE = 'date',
  EMAIL = 'email',
  HIDDEN = 'hidden',
  IMAGE = 'image',
  MONTH = 'month',
  NUMBER = 'number',
  PASSWORD = 'password',
  RANGE = 'range',
  SEARCH = 'search',
  TEL = 'tel',
  TEXT = 'text',
  TIME = 'time',
  URL = 'url',
  WEEK = 'week'
}

export interface FdInputConfig {
  controlName: string;
  label: string;
  tooltipMessage?: string;
  hint?: string;
  iconHTML?: SafeHtml;
  maxLength?: number;
  disabled?: boolean;
  type?: InputType;
  mask?: Mask | ((value: string) => Mask);
  maskCharsReplace?: RegExp;
  messages?: { [key: string]: string };
  error?: boolean;
  isNumber?: boolean;
}
