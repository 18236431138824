import { Item } from "src/app/shared/fd-form-components/fd-select/fd-select.component";
import { TechnologyInformationDto } from "./technology-information-dto";

export class ParamRates {
  personType: string;
  cnae: string;
  annualBillingValue: Number;
  idCampaign: Number;
  campaignCode?: string;
  uf: string;
  receivingDeadline: Number;
  chargeModelEnum: string;
  cpfCnpj: string;
  automaticPrepayment: boolean;
  offerMessage?: string;
  hasEconomicGroup: boolean;
  institutionId: string;
  serviceContract: Number;
  campaignName?: string;
  techReport:  Array<TechReportDTO>;
}

export class TechReportDTO{
  name: string;
  value: string;
  totalValue:string;
  qtd: Number;
}

export class Phrases {
  exemptionPolicyPhraseFactorFlex:string;
  phrase: string;
  pricingTechnologyPhrase: string;
  disclaimerPhrase: string;
  disclaimerTechPhrase: string;
  offerMessage: string;
}

export class CampaignSimulator extends Item {
  idCampaign: Number;
  campaignCode: string;
  description: string;
  hasMdrFlex: boolean;
  hasMdr: boolean;
  mdrWithPrepayment: boolean;
  minAnnualBilling: Number;
  maxAnnualBilling: Number;
  hasNegotiation: boolean;
  technologyList: Array<TechnologyInformationDto>;
  amountTechs: Array<Number>;
}
