import { FeeTypeEnum } from 'src/app/order/enums/fee-type.enum';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Phrases } from './param';
import { TechnologyInformationDto } from './technology-information-dto';

export class OfferQueryDataModel extends Item {
  public campaignId: number;
  public campaignCode: number;
  public feeType: FeeTypeEnum;
  public isPrepaymentEnabled: boolean;
  public campaignName?: string;
  public billingRangeStart: string;
  public billingRangeEnd: string;
  public monthBillingStart: string;
  public monthBillingEnd: string;
  public monthBillingStartRounded: number;
  public monthBillingEndRounded: number;
  public posPriceText: string;
  public phrases: Phrases;
  public hasNegotiation: boolean;
  public hasEconomicGroup: boolean;
  public technologyList: Array<TechnologyInformationDto>
  public amountTechs: Array<Number>
  public minAnnualBilling: Number;
  public maxAnnualBilling: Number;


  public getOfferMessage(): string {
    if(this.hasEconomicGroup){
      return 'A Fiserv poderá, a qualquer momento, alterar os preços que foram contratados.';
  }

    if ([49, 50,51].includes(this.campaignCode)) {
      return 'A Fiserv poderá, a qualquer momento, alterar os preços que foram contratados.';
    }

    if (this.monthBillingStartRounded && this.monthBillingStart && this.monthBillingStartRounded >= 8300000 && this.campaignCode !== 51) {
      return (
        'Caso não ocorra o faturamento acima de ' +
        this.monthBillingStart +
        ' ao mês, a Fiserv poderá, ' +
        'a qualquer momento, alterar os preços que foram contratados.'
      );
    }

    if (this.monthBillingStart && this.monthBillingEnd && this.campaignCode !== 51) {
      return (
        ' Caso não ocorra o faturamento entre ' +
        this.monthBillingStart +
        ' e ' +
        this.monthBillingEnd +
        ' ao mês, a Fiserv poderá, a qualquer momento, alterar os preços que foram ' +
        'contratados.'
      );
    }
  }
}

export class NegociationDataModel {
  public registrationNumber: string;
  public agencyName: string;
  public email: string;
  public cnpj: string;
  public formCnae: number;
  public socialReason: string;
  public annualRevenue: number;
  public prepaymentEnabled: boolean;
  public posAmount: number;
  public terminalUnitPrice: number;
  public requestedExceptions: string;
  public revenuePercentCredit: number;
  public revenuePercentDebit: number;
  public revenuePercent2to6: number;
  public revenuePercent7to12: number;
  public suggestedFeesCredit: number;
  public suggestedFeesDebit: number;
  public suggestedFees2to6: number;
  public suggestedFees7to12: number;

  public suggestedEloOthersFeesCredit: number;
  public suggestedEloOthersFeesDebit: number;
  public suggestedEloOthersFees2to6: number;
  public suggestedEloOthersFees7to12: number;
}
