import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { OfferQueryDataModel } from '../fees-data/models/offer-query-data.model';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { TechEventDTO, TechnologyInformationDto, TechnologyInformationTableDto } from '../fees-data/models/technology-information-dto';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { MatTableDataSource } from '@angular/material/table';
import { getValueWithDecimalPlaces } from 'src/app/order/utils/decimal-places';


@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss']
})
export class AdditionalInformationComponent implements OnInit {

  @Input() public offerQueryData: OfferQueryDataModel;

  @Output() sendTechsInformation = new EventEmitter<any>();

  dataSourceTable: MatTableDataSource<TechnologyInformationTableDto> = new MatTableDataSource();

  newCommercialActionCampaig: number[] = [32,33,34,35,40,41];

  @Input() enableSimulatorAudit: boolean;

  showTable = false;

  formGroup: UntypedFormGroup = this.createFormGroup();
  fields: FdFieldConfigs = this.createFields();

  techEventDTO = new TechEventDTO();
  
  displayedColumns =
    ['tradeName','unitValue','fullValue'];


  constructor(private formBuilder: UntypedFormBuilder) { 
    
  }

  ngOnInit() { 
    console.log("Campanha:"+this.offerQueryData.campaignName);
    console.log("Range Start:"+this.offerQueryData.billingRangeStart);
    console.log("Range End:"+this.offerQueryData.billingRangeEnd);
    this.getList();
    this.formGroup.controls.amount.disable();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.clearForm();
    this.getList();
  }

  public clearForm(){
    this.formGroup.controls.techs.setValue('');
    this.formGroup.controls.amount.setValue('');
    this.formGroup.controls.amount.disable();
    
    const techInfo = new TechnologyInformationDto;
    techInfo.amount = null;
    techInfo.tradeName = null;

    this.buildObjectAndSendEvent(techInfo,new TechnologyInformationTableDto());
  }

  public getList(){
      this.showTable = false;
      
      this.techsDropdown.items = [];
      this.techsDropdown.items.push(...this.offerQueryData.technologyList.map((c) => this.convert(c)));

      this.amountDropdown.items = [];
      this.amountDropdown.items.push(...this.offerQueryData.amountTechs.map((c) => this.convertAmount(c)));
  }

  filterTechnologyDropdownItems(filterValue) {
    if (!filterValue) {
      this.techsDropdown.items = [];
      this.techsDropdown.items.push(...this.offerQueryData.technologyList.map((c) => this.convert(c)));
      return;
    }
    this.techsDropdown.items = [];
    this.techsDropdown.items.push(...this.offerQueryData.technologyList.map((c) => this.convert(c)));
    const filteredItems = this.techsDropdown.items.filter(tech => tech.value.toLowerCase().includes(filterValue.toLowerCase()));
    this.techsDropdown.items = filteredItems;
  }

  filterAmountDropdownItems(filterValue) {
    if (!filterValue) {
      this.amountDropdown.items = [];
      this.amountDropdown.items.push(...this.offerQueryData.amountTechs.map((c) => this.convertAmount(c)));
      return;
    }
    this.amountDropdown.items = [];
    this.amountDropdown.items.push(...this.offerQueryData.amountTechs.map((c) => this.convertAmount(c)));
    const filteredItems = this.amountDropdown.items.filter(amount => amount.value == filterValue);
    this.amountDropdown.items = filteredItems;
  }

  public onChangeTech(event){
    let constArrayTable: TechnologyInformationTableDto[] = [];
    let tableObject: TechnologyInformationTableDto = new TechnologyInformationTableDto();
    
    const techInfo = this.offerQueryData.technologyList.find((x) => x.tradeName === event.value);

    if(this.showTable){
      tableObject.totalValue = getValueWithDecimalPlaces(techInfo.suggestedTechPrice * this.formControls.amount.value,2);
      techInfo.amount = this.formControls.amount.value;
    }else{
      tableObject.totalValue = getValueWithDecimalPlaces(techInfo.suggestedTechPrice,2);
      techInfo.amount = null;
    }
    
    tableObject.suggestedTechPrice = getValueWithDecimalPlaces(techInfo.suggestedTechPrice,2);
    tableObject.tradeName = techInfo.tradeName;
    
    constArrayTable.push(tableObject);
    this.showTable = true;
    this.dataSourceTable.data = constArrayTable;

    this.formGroup.controls.amount.enable();

    this.buildObjectAndSendEvent(techInfo,tableObject);
  }

  public onChangeAmount(event){  
    let constArrayTable: TechnologyInformationTableDto[] = [];
    let tableObject: TechnologyInformationTableDto = new TechnologyInformationTableDto();

    const techInfo = this.offerQueryData.technologyList.find((x) => x.tradeName === this.formControls.techs.value);
    techInfo.amount = event.value;
    
    tableObject.totalValue = getValueWithDecimalPlaces(techInfo.suggestedTechPrice * event.value);
    tableObject.suggestedTechPrice = getValueWithDecimalPlaces(techInfo.suggestedTechPrice,2);
    tableObject.tradeName = techInfo.tradeName;
    
    constArrayTable.push(tableObject);
    this.dataSourceTable.data = constArrayTable;

    this.buildObjectAndSendEvent(techInfo,tableObject);
  }

  private buildObjectAndSendEvent(techInfo:TechnologyInformationDto, objectForReport: TechnologyInformationTableDto){
    this.techEventDTO.techInfo = techInfo;
    this.techEventDTO.techTableInfo = objectForReport;

    this.sendTechsInformation.emit(this.techEventDTO);
  }

  get techsDropdown() {
    return (this.fields.techs as FdSelectConfig);
  }

  get amountDropdown() {
    return (this.fields.amount as FdSelectConfig);
  }

  private convertAmount(e : Number): Item{
    let item: Item = new Item();
    item.label = e.toString();
    item.value = e;
    return item;
  }

  private convert(e: TechnologyInformationDto): Item {
    let item: Item = new Item();
      item.label = e.tradeName;
      item.value = e.tradeName;

      return item;
  }
  

  public isCampanhaPinpad(){
    return this.offerQueryData.campaignCode === 51;
  }

  
  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  protected createFields() {
    return {
      techs: {
        label: 'Tecnologias',
        items: [],
        searchable: true,
        searchPlaceholder: 'Selecione a tecnologia',
        controlName: 'techs',
        messages: {
          required: 'Informe a tecnologia',
          invalid: 'Tecnologia inválida'
        }
      }, 
      amount: {
        label: 'Quantidade',
        items: [],
        searchable: true,
        searchPlaceholder: 'Selecione a quantidade',
        controlName: 'amount',
        messages: {
          required: 'Informe a quantidade',
          invalid: 'Quantidade inválida'
        }
      }
  }
  }
  protected createFormGroup() {
    return this.formBuilder.group({
      techs: [{value: ''}],
      amount: [{value: ''}]
      
    });
  }

}
