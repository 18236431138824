import { Component,  OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { EconomicGroupService } from '../../services/external/economic-group/economic-group.service';
import { AbstractControl, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ActivatedRoute } from '@angular/router';
import { DataStoreService } from '../../store/data-store.service';
import { FormBase } from '../form-base';
import { ScrollService } from '../../services/internal/scroll/scroll.service';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { cnpjMask } from '../../masks/document-masks';
import { SimulationSearchResponse } from '../simulation-economical-group/models/simulation-search.model';
import { CnpjValidator } from '../../validators/cpf-cnpj-validator';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { SimulationEconomicalGroupDetail } from './simulation-economical-group-detail/simulation-economical-group-detail.component';
import { SimulationEconomicalGroupPricingDetailComponent } from './simulation-economical-group-pricing-detail/simulation-economical-group-pricing-detail.component';
import { finalize } from "rxjs/operators";
import { DialogService } from '../../services/internal/dialog/dialog.service';
import { Messages } from '../../messages/order.messages';
import { HttpEventType } from '@angular/common/http';
import { CreateEconomicGroupDTO } from '../simulation-economical-group/models/create-economic-group-dto.model';
import {ErrorService} from "../../services/internal/error/error.service";


@Component({
    selector: 'simulation-search',
    templateUrl: './simulation-search.component.html',
    styleUrls: ['./simulation-search.component.scss']
  })
export class SimulationSearch extends FormBase implements OnInit {


  dataSource: MatTableDataSource<SimulationSearchResponse>;

  @Output() changePage = new EventEmitter<PageEvent>();

  private paginator: MatPaginator;
  private sort: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  managerCod: string;

  displayedColumns: string[] = ['simulationCod','monthlyBillingVal','simulationDate','expirationDate','simulationStatusdescription', 'simulationPricingDetails','contractDownload', 'createEconomicGroup', 'simulationDetails'];

  sizeItems = 50;
  totalPages = 0;
  pageNumber = 0;
  hasValue = false;
  previousSizeItems = 0;
  defaultSizeItems = 50;


  formGroup: UntypedFormGroup = this.createFormGroup();
  fields: FdFieldConfigs = this.createFields();

  constructor(
    private economicGroupService:EconomicGroupService,
    private formBuilder: UntypedFormBuilder,
    private router: ActivatedRoute,
    protected scrollService: ScrollService,
    private dataStore: DataStoreService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private errorService: ErrorService){
    super(scrollService);
  }

    ngOnInit() {
      let param = {};
      this.formControls.cnpj.setValue('');

        if (this.router.snapshot.queryParams["key"]) {
          this.economicGroupService.decryptParamsSimulationSearch(this.router.snapshot.queryParams["key"],this.pageNumber,this.sizeItems)
          .toPromise().then((res) =>{

            if (res.pageableSimulationList.response.length > 0){
              this.hasValue = true;
              res.pageableSimulationList.response = this.formatData(res.pageableSimulationList.response);
            }

            this.dataSource = new MatTableDataSource(res.pageableSimulationList.response);

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;


            this.totalPages = res.pageableSimulationList.totalPages;
            this.pageNumber = res.pageableSimulationList.pageNumber;

            param['matricula'] = res.managerCod;

            this.dataStore.setParam(param);
          })
        } else {
          this.economicGroupService.getSimulationList(this.dataStore.getRegisterUserCode(),this.formControls.cnpj.value,this.pageNumber,this.sizeItems)
          .toPromise().then((res) =>{
            if (res.response.length > 0){
              this.hasValue = true;
              res.response = this.formatData(res.response);
            }

            this.dataSource = new MatTableDataSource(res.response);

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            this.totalPages = res.totalPages;
            this.pageNumber = res.page;
          });
        }

    }

    setDataSourceAttributes() {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }

    formatData(data: SimulationSearchResponse[]): any {
        data.forEach((simulation) =>{
          simulation.monthlyBillingVal = parseFloat(simulation.monthlyBillingVal).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
          simulation.simulationCod = simulation.simulationCod.toString().padStart(8,'0');
        })
        return data;
    }

    protected createFormGroup(): UntypedFormGroup {
      return this.formBuilder.group({
        cnpj: [{value: '', disabled: false}, CnpjValidator]
    });
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

  async getSimulationsByFilter(){

    const managerFilter = this.formControls.cnpj.value === '' ? this.dataStore.getRegisterUserCode() : '';

    await this.economicGroupService.getSimulationList(managerFilter,this.formControls.cnpj.value,this.pageNumber,this.sizeItems).toPromise().then((res) =>{

      if (res.response.length > 0){
        this.hasValue = true;
        res.response = this.formatData(res.response);
      }

      this.dataSource = new MatTableDataSource(res.response);

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.totalPages = res.totalPages;
      this.pageNumber = res.page;

    }).catch((error)=>{
      this.dialogService.openDialog(Messages.LIST_SIMULATION_ERROR);
    });

  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  protected createFields() {
    return {
      cnpj: {
        label: 'CNPJ',
        controlName: 'cnpj',
        type: InputType.TEL,
        mask: cnpjMask,
        isNumber: true,
        maskCharsReplace: /[.\/ -]/g,
        messages: {
          required: 'Informe o CNPJ',
          invalidCnpj: 'CNPJ inválido'
        }
      }
    };
  }

  detail(simulation: SimulationSearchResponse) {
    this.dialog.open(SimulationEconomicalGroupDetail, {
      width: '55%',
      height: '75%',
      data: simulation.simulationCod
    })
  }

  pricingDetail(simulationCod: number) {
    this.dialog.open(SimulationEconomicalGroupPricingDetailComponent, {
      width: '80%',
      height: '85%',
      data: simulationCod
    })
  }

  createGroup(simulationCod: number) {
   let createEconomicGroupDTO = new CreateEconomicGroupDTO();
   createEconomicGroupDTO.idRegistrationManager = this.dataStore.getRegisterUserCode();
   createEconomicGroupDTO.idSimulation = simulationCod;

    this.economicGroupService.createGroup(createEconomicGroupDTO).toPromise()
    .then(res =>{
      if(res.idEconomicGroup){
        this.dialogService.openDialog(Messages.CREATE_ECONOMIC_GROUP_SUCCESS);
        this.getSimulationsByFilter();
      }

    }).catch((error)=>{
      console.log(error);
      if (error) {
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR)
      }
    })
  }


  downloadContract(simulationCod: number){
    this.economicGroupService.getContractFile(simulationCod,this.dataStore.getRegisterUserCode())
      .pipe(finalize(() => {
        this.getSimulationsByFilter();
      }))
      .subscribe((response) =>{
      if (response.type === HttpEventType.Response) {
        const blob = new Blob([response.body], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        let newWin = window.open(url);
        if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
          this.processDownload(url);
        }
        this.dialogService.openDialogWithHtmlMessage  (Messages.SUCCESSFUL_CONTRACT_DOWNLOAD)
      }

    },async ( error) => {
        let mainError = JSON.parse(await error.error.text()).mainError;
        if (mainError) {
          let message = new Messages("Atenção", mainError);
          this.dialogService.openDialogWithHtmlMessage(message);
        } else {
          this.dialogService.openDialog(Messages.CONTRACT_SIMULATION_ERROR);
        }
    });

  }

  private processDownload(url: any) {
    let link = document.createElement("a");
    link.href = url;
    link.download = "ContratoDeGrupoComercialAzulzinha.pdf";
    link.innerHTML = "click";
    link.click();
    window.URL.revokeObjectURL(url);
  }

}





